@import url(https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.message-body {
  white-space: pre-wrap;
}

.tag {
  white-space: normal !important;
  display: inline !important;
  padding: 5px 8px !important;
  font-size: 1.05rem !important;
  vertical-align: inherit !important;
}

.tag a,
.message a {
  color: inherit !important;
  text-decoration: none !important;
}

.section {
  padding-top: 1.5rem !important;
}

.square {
  border-radius: 0;
}

.section.with-custom-padding-top {
  padding-top: 3rem !important;
}

.section.without-padding-top {
  padding-top: 0 !important;
}

.pagination-link.is-current {
  background-color: #363636;
  border-color: #363636;
}

.menu-list a.is-active {
  background-color: #f5f5f5;
}

.tabs li.is-active a {
  border-color: #363636;
  color: #363636;
}

a.navbar-item.is-active,
a.navbar-item:hover {
  color: #363636;
}

a {
  color: #363636;
}

.is-flashcard {
  float: right;
  margin-top: -5px;
  margin-right: -20px;
  min-width: 35px;
  text-align: center;
}

.question,
.checkbox,
.radio {
  color: #363636 !important;
}

